import React from "react";
import Layout from "../components/Layout";
import { ImageConstants } from "../constants/Imagconstants";
const privacyPolicy = () => {
return (
<Layout title={"Razrcorp | privacy-policy"}>
<main>
   <section>
      <div className="container">
         <div className="row">
            <div className="col-12">
               <div className="subpageheader pb-5">
                  <h1>Privacy Policy</h1>
                  <p className="pb-3">
                     This Application collects some Personal Data from its Users. Users may be subject to different protection standards and broader standards may therefore apply to some. In order to learn more about the protection criteria, Users can refer to the applicability section.
                  </p>
                  <div className="hire-bg-1">
                     <img
                        loading="lazy"
                        src={ImageConstants.privacy1}
                        alt="design"
                        />
                  </div>
                  <div className="hire-bg-2">
                     <img
                        loading="lazy"
                        src={ImageConstants.backendIcon}
                        alt="design"
                        />
                  </div>
                  <div className="hire-bg-3">
                     <img
                        loading="lazy"
                        src={ImageConstants.backendIcon2}
                        alt="design"
                        />
                  </div>
                  <div className="backend-bg-1">
                     <img
                        loading="lazy"
                        src={ImageConstants.privacy2}
                        alt="design"
                        />
                  </div>
                  <div className="hire-bg-4">
                     <img
                        loading="lazy"
                        src={ImageConstants.cyberSecurityBg3}
                        alt="design"
                        />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section>
      <div className="container">
         <div className="row">
            <div className="col-md-12">
              <div className="privacy-box">
                <div className="mrbottom">
                  <h3>Owner and Data Controller</h3>
                  <p>
                     Razr Tech SARL,9-Avenues Des Hauts Fourneaux,
                     <br />
                     Esch Sur Alzette-4362
                     <br />
                    Owner contact email:{" "}
                     <a href="mailto:globalops@razrcorp.com">
                     globalops@razrcorp.com
                     </a>
                  </p>
                </div>
                   <div className="mrbottom">
                  <h3>Types of Data collected</h3>
                  <p>
                     Among the types of Personal Data that this Application
                     collects, by itself or through third parties, there are:
                     Cookies, Usage Data, Data communicated while using the service
                     and email address.
                  </p>
                  <p>
                     Complete details on each type of Personal Data collected are
                     provided in the dedicated sections of this privacy policy or
                     by specific explanation texts displayed prior to the Data
                     collection.
                  </p>
                  <p>
                     Personal Data may be freely provided by the User, or, in case
                     of Usage Data, collected automatically when using this
                     Application.
                  </p>
                  <p>
                     Unless specified otherwise, all Data requested by this
                     Application is mandatory and failure to provide this Data may
                     make it impossible for this Application to provide its
                     services. In cases where this Application specifically states
                     that some Data is not mandatory, Users are free not to
                     communicate this Data without consequences to the availability
                     or the functioning of the Service.
                  </p>
                  <p>
                     Users who are uncertain about which Personal Data is mandatory
                     are welcome to contact the Owner.
                  </p>
                  <p>
                     Any use of Cookies – or of other tracking tools – by this
                     Application or by the owners of third-party services used by
                     this Application serves the purpose of providing the Service
                     required by the User, in addition to any other purposes
                     described in the present document and in the Cookie Policy, if
                     available.
                  </p>
                  <p>
                     Users are responsible for any third-party Personal Data
                     obtained, published or shared through this Application and
                     confirm that they have the third party's consent to provide
                     the Data to the Owner.
                  </p>
                </div>
              
                  <h3>
                     Mode and place of processing the Data
                </h3>
                <div className="mrbottom">
                  <h5>Methods of processing</h5>
                  <p>
                     The Owner takes appropriate security measures to prevent
                     unauthorized access, disclosure, modification, or unauthorized
                     destruction of the Data.
                  </p>
                  <p>
                     The Data processing is carried out using computers and/or IT
                     enabled tools, following organizational procedures and modes
                     strictly related to the purposes indicated. In addition to the
                     Owner, in some cases, the Data may be accessible to certain
                     types of persons in charge, involved with the operation of
                     this Application (administration, sales, marketing, legal,
                     system administration) or external parties (such as
                     third-party technical service providers, mail carriers,
                     hosting providers, IT companies, communications agencies)
                     appointed, if necessary, as Data Processors by the Owner. The
                     updated list of these parties may be requested from the Owner
                     at any time.
                  </p>
                </div>
                <div className="mrbottom">
                  <h5>Legal basis of processing</h5>
                  <p>
                     The Owner may process Personal Data relating to Users if one
                     of the following applies:
                  </p>
                  <ul>
                     <li>
                        Users have given their consent for one or more specific
                        purposes. Note: Under some legislations the Owner may be
                        allowed to process Personal Data until the User objects to
                        such processing (“opt-out”), without having to rely on
                        consent or any other of the following legal bases. This,
                        however, does not apply, whenever the processing of Personal
                        Data is subject to European data protection law;
                     </li>
                     <li>
                        Provision of Data is necessary for the performance of an
                        agreement with the User and/or for any pre-contractual
                        obligations thereof;
                     </li>
                     <li>
                        Processing is necessary for compliance with a legal
                        obligation to which the Owner is subject;
                     </li>
                     <li>
                        Processing is related to a task that is carried out in the
                        public interest or in the exercise of official authority
                        vested in the Owner;
                     </li>
                     <li>
                        Processing is necessary for the purposes of the legitimate
                        interests pursued by the Owner or by a third party.
                     </li>
                  </ul>
                  <p>
                     In any case, the Owner will gladly help to clarify the
                     specific legal basis that applies to the processing, and in
                     particular whether the provision of Personal Data is a
                     statutory or contractual requirement, or a requirement
                     necessary to enter into a contract.
                  </p>
                </div>
                 <div className="mrbottom">
                  <h5>Place</h5>
                  <p>
                     The Data is processed at the Owner's operating offices and in
                     any other places where the parties involved in the processing
                     are located.
                  </p>
                  <p>
                     Depending on the User's location, data transfers may involve
                     transferring the User's Data to a country other than their
                     own. To find out more about the place of processing of such
                     transferred Data, Users can check the section containing
                     details about the processing of Personal Data.
                  </p>
                  <p>
                     If broader protection standards are applicable, Users are also
                     entitled to learn about the legal basis of Data transfers to a
                     country outside the European Union or to any international
                     organization governed by public international law or set up by
                     two or more countries, such as the UN, and about the security
                     measures taken by the Owner to safeguard their Data.
                  </p>
                  <p>
                     If any such transfer takes place, Users can find out more by
                     checking the relevant sections of this document or inquire
                     with the Owner using the information provided in the contact
                     section.
                  </p>
                </div>
                <div className="mrbottom">
                  <h5>Retention time</h5>
                  <p>
                     Personal Data shall be processed and stored for as long as
                     required by the purpose they have been collected for.
                  </p>
                  <p><strong> Therefore: </strong></p>
                  <ul>
                     <li>
                        Personal Data collected for purposes related to the
                        performance of a contract between the Owner and the User
                        shall be retained until such contract has been fully
                        performed.
                     </li>
                     <li>
                        Personal Data collected for the purposes of the Owner’s
                        legitimate interests shall be retained as long as needed to
                        fulfill such purposes. Users may find specific information
                        regarding the legitimate interests pursued by the Owner
                        within the relevant sections of this document or by
                        contacting the Owner.
                     </li>
                  </ul>
                  <p>
                     The Owner may be allowed to retain Personal Data for a longer
                     period whenever the User has given consent to such processing,
                     as long as such consent is not withdrawn. Furthermore, the
                     Owner may be obliged to retain Personal Data for a longer
                     period whenever required to do so for the performance of a
                     legal obligation or upon order of an authority.
                  </p>
                  <p>
                     Once the retention period expires, Personal Data shall be
                     deleted. Therefore, the right to access, the right to erasure,
                     the right to rectification and the right to data portability
                     cannot be enforced after expiration of the retention period.
                </p>
                </div>
                 <div className="mrbottom">
                  <h5>The purposes of processing</h5>
                  <p>
                     The Data concerning the User is collected to allow the Owner
                     to provide its Services, as well as for the following
                     purposes: Analytics, Interaction with live chat platforms and
                     Contacting the User.
                  </p>
                  <p>
                     Users can find further detailed information about such
                     purposes of processing and about the specific Personal Data
                     used for each purpose in the respective sections of this
                     document.
                  </p>
                </div>
                 <div className="mrbottom">
                  <h5>Detailed information on the processing of Personal Data </h5>
                  <p>
                  Personal Data is collected for the following purposes and
                  using the following services:
                  </p>
                  <h4>1) Analytics</h4>
                  <h4>2) Contacting the User</h4>
                  <h4>3) Interaction with live chat platforms</h4>
                
                </div>
                 <div className="mrbottom">
                  <h5>The rights of Users</h5>
                  <p>
                     Users may exercise certain rights regarding their Data
                     processed by the Owner.
                  </p>
                  <p>
                     Users entitled to broader protection standards may exercise
                     any of the rights described below. In all other cases, Users
                     may inquire with the Owner to find out which rights apply to
                     them.
                  </p>
                  <p>In particular, Users have the right to do the following:</p>
                  <ul>
                     <li>
                        <strong>Withdraw their consent at any time.</strong> Users
                        have the right to withdraw consent where they have
                        previously given their consent to the processing of their
                        Personal Data.
                     </li>
                     <li>
                        <strong>Object to processing of their Data.</strong> Users
                        have the right to object to the processing of their Data if
                        the processing is carried out on a legal basis other than
                        consent. Further details are provided in the dedicated
                        section below.
                     </li>
                     <li>
                        <strong>Access their Data.</strong> Users have the right to
                        learn if Data is being processed by the Owner, obtain
                        disclosure regarding certain aspects of the processing and
                        obtain a copy of the Data undergoing processing.
                     </li>
                     <li>
                        <strong>Verify and seek rectification.</strong> Users have
                        the right to verify the accuracy of their Data and ask for
                        it to be updated or corrected.
                     </li>
                     <li>
                        <strong>Restrict the processing of their Data.</strong>{" "}
                        Users have the right, under certain circumstances, to
                        restrict the processing of their Data. In this case, the
                        Owner will not process their Data for any purpose other than
                        storing it.
                     </li>
                     <li>
                        <strong>
                        Have their Personal Data deleted or otherwise removed.
                        </strong>{" "}
                        Users have the right, under certain circumstances, to obtain
                        the erasure of their Data from the Owner.
                     </li>
                     <li>
                        <strong>
                        Receive their Data and have it transferred to another
                        controller.
                        </strong>{" "}
                        Users have the right to receive their Data in a structured,
                        commonly used and machine readable format and, if
                        technically feasible, to have it transmitted to another
                        controller without any hindrance. This provision is
                        applicable provided that the Data is processed by automated
                        means and that the processing is based on the User's
                        consent, on a contract which the User is part of or on
                        pre-contractual obligations thereof.
                     </li>
                     <li>
                        <strong>Lodge a complaint.</strong> Users have the right to
                        bring a claim before their competent data protection
                        authority.
                     </li>
                  </ul>
                </div>
                 <div className="mrbottom">
                  <h6>
                     Details about the right to object to processing
                  </h6>
                  <p>
                     Where Personal Data is processed for a public interest, in the
                     exercise of an official authority vested in the Owner or for
                     the purposes of the legitimate interests pursued by the Owner,
                     Users may object to such processing by providing a ground
                     related to their particular situation to justify the
                     objection.
                  </p>
                  <p>
                     Users must know that, however, should their Personal Data be
                     processed for direct marketing purposes, they can object to
                     that processing at any time without providing any
                     justification. To learn, whether the Owner is processing
                     Personal Data for direct marketing purposes, Users may refer
                     to the relevant sections of this document.
                  </p>
                </div>
                 <div className="mrbottom">
                  <h6>How to exercise these rights</h6>
                  <p>
                     Any requests to exercise User rights can be directed to the
                     Owner through the contact details provided in this document.
                     These requests can be exercised free of charge and will be
                     addressed by the Owner as early as possible and always within
                     one month.
                  </p>
                </div>
                 <div className="mrbottom">
                  <h5>
                     Applicability of broader protection standards
                  </h5>
                  <p>
                     While most provisions of this document concern all Users, some
                     provisions expressly only apply if the processing of Personal
                     Data is subject to broader protection standards.
                  </p>
                  <p>
                     Such broader protection standards apply when the processing:
                  </p>
                  <ul>
                     <li>is performed by an Owner based within the EU;</li>
                     <li>
                        concerns the Personal Data of Users who are in the EU and is
                        related to the offering of paid or unpaid goods or services,
                        to such Users;
                     </li>
                     <li>
                        concerns the Personal Data of Users who are in the EU and
                        allows the Owner to monitor such Users’ behavior taking
                        place in the EU.
                     </li>
                  </ul>
                </div>
                  <div className="mrbottom">
                  <h5>
                     Additional information about Data collection and processing
                  </h5>
                  <h6>Legal action</h6>
                  <p>
                     The User's Personal Data may be used for legal purposes by the
                     Owner in Court or in the stages leading to possible legal
                     action arising from improper use of this Application or the
                     related Services.
                  </p>
                  <p>
                     The User declares to be aware that the Owner may be required
                     to reveal personal data upon request of public authorities.
                  </p>
                </div>
                 <div className="mrbottom">
                  <h6>Additional information about User's Personal Data</h6>
                  <p>
                     In addition to the information contained in this privacy
                     policy, this Application may provide the User with additional
                     and contextual information concerning particular Services or
                     the collection and processing of Personal Data upon request.
                  </p>
                </div>
                 <div className="mrbottom">
                  <h6>System logs and maintenance</h6>
                  <p>
                     For operation and maintenance purposes, this Application and
                     any third-party services may collect files that record
                     interaction with this Application (System logs) use other
                     Personal Data (such as the IP Address) for this purpose.
                  </p>
                </div>
                 <div className="mrbottom">
                  <h6>Information not contained in this policy</h6>
                  <p>
                     More details concerning the collection or processing of
                     Personal Data may be requested from the Owner at any time.
                     Please see the contact information at the beginning of this
                     document.
                  </p>
                </div>
                 <div className="mrbottom">
                  <h6>How “Do Not Track” requests are handled</h6>
                  <p>
                     This Application does not support “Do Not Track” requests.
                  </p>
                  <p>
                     To determine whether any of the third-party services it uses
                     honor the “Do Not Track” requests, please read their privacy
                     policies.
                  </p>
                </div>
                 <div className="mrbottom">
                  <h6>Changes to this privacy policy</h6>
                  <p>
                     The Owner reserves the right to make changes to this privacy
                     policy at any time by giving notice to its Users on this page
                     and possibly within this Application and/or - as far as
                     technically and legally feasible - sending a notice to Users
                     via any contact information available to the Owner. It is
                     strongly recommended to check this page often, referring to
                     the date of the last modification listed at the bottom.
                  </p>
                  <p>
                     Should the changes affect processing activities performed on
                     the basis of the User’s consent, the Owner shall collect new
                     consent from the User, where required.
                  </p>
                 
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</main>
</Layout>
);
};
export default privacyPolicy;